export const isLocalhostEnv = () => process.env.NODE_ENV === 'development'
export const isTestEnv = () => location.hostname.includes('test')
export const isProdEnv = () => !(isLocalhostEnv() || isTestEnv())

let _env: EnvConfig
export function env(val?: EnvConfig) {
  if (val) _env = val
  return _env
}

interface EnvConfig {
  apiBase: string
  apiauthBase: string
  graphQlBase: string
  tokenUrl: string
  notificationHub: string
  baseUrl: string
  forSkolaUrl: string
  grundSkolaUrl: string
  gymnasiumUrl: string
  username?: string
  password?: string
}

const testEnvConfig: EnvConfig = {
  tokenUrl: 'https://test.idp.skolanalys.se/connect/token',
  apiauthBase: 'https://test.idp.skolanalys.se/api',
  apiBase: 'https://api.test.grundskola.skolanalys.se/api',
  graphQlBase: 'https://api.test.grundskola.skolanalys.se/graphql',
  notificationHub: 'https://api.test.grundskola.skolanalys.se/hubs/notification',
  baseUrl: 'https://test.skolanalys.se/',
  forSkolaUrl: 'https://test.forskola.skolanalys.se/',
  grundSkolaUrl: 'https://test.grundskola.skolanalys.se/',
  gymnasiumUrl: 'https://test.gymnasium.skolanalys.se/'
}

const prodEnvConfig: EnvConfig = {
  tokenUrl: 'https://idp.skolanalys.se/connect/token',
  apiBase: 'https://api.grundskola.skolanalys.se/api',
  apiauthBase: 'https://idp.skolanalys.se/api',
  graphQlBase: 'https://api.grundskola.skolanalys.se/graphql',
  notificationHub: 'https://api.grundskola.skolanalys.se/hubs/notification',
  baseUrl: 'https://skolanalys.se/',
  forSkolaUrl: 'https://forskola.skolanalys.se/',
  grundSkolaUrl: 'https://grundskola.skolanalys.se/',
  gymnasiumUrl: 'https://gymnasium.skolanalys.se'
}

const localEnvConfig: EnvConfig = {
  ...testEnvConfig,
  username: process.env.REACT_APP_EMAIL,
  password: process.env.REACT_APP_PASSWORD,
}

const useLocalAPI = false
const localAPIEnvConfig: EnvConfig = {
  tokenUrl: 'https://localhost:5001/connect/token',
  apiBase: 'https://api.test.grundskola.skolanalys.se/api',
  apiauthBase: 'https://localhost:44354/api',
  graphQlBase: 'https://localhost:44354/graphql',
  notificationHub: 'https://localhost:44354/api/hubs/notification',
  baseUrl: 'http://localhost:3000/',
  forSkolaUrl: 'https://localhost:3000/#Bakgrund',
  grundSkolaUrl: 'https://localhost:3000/#Bakgrund',
  gymnasiumUrl: 'https://localhost:3000/#Bakgrund',
  username: process.env.REACT_APP_EMAIL,
  password: process.env.REACT_APP_PASSWORD,
}

export const populateEnv = () =>
  isProdEnv()
    ? env(prodEnvConfig)
    : isLocalhostEnv()
      ? env(useLocalAPI ? localAPIEnvConfig : localEnvConfig)
      : env(testEnvConfig)
