import React from 'react'
import FullPageSpinner from './FullPageSpinner'
import { Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
export default function AuthLoading() {
  const {t} = useTranslation()
  return (
    <FullPageSpinner>
      <Text color="white" fontSize="2xl">
      {t('Authentication')}...
      </Text>
    </FullPageSpinner>
  )
}
